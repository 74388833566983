import React, { useEffect, useState } from 'react';
import {
  Link
} from "react-router-dom";
import './Herosection.css';
import dummy_video_1 from '../../assets/videos/dummy_video_1.mp4';
import background99 from '../../assets/images/background_99.jpg';

export default function Herosection() {
  // State to handle animation effects
  const [animate, setAnimate] = useState(false);
 

  useEffect(() => {
    // Trigger animation after component mounts
    setTimeout(() => setAnimate(true), 100); // Delay the animation for smooth effect
  }, []);

  return (
    <section className="hero-section">
      {/* Background Video */}
      <div className="hero-background">
        <video autoPlay muted loop poster={background99}  className="background-video">
          <source src={dummy_video_1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Hero Content */}
      <div className={`hero-content ${animate ? 'fade-in-up' : ''}`}>
        <h1>Innovative Web and Android Solutions for Your Business</h1>
        <p>Empowering businesses with cutting-edge website development, Android app solutions, and digital marketing expertise.</p>
        <div className="cta-buttons">
          <Link to="/services" className="cta-button primary">Explore Our Services</Link>
          <Link to="/contact-us" className="cta-button secondary">Get in Touch</Link>
        </div>
      </div>
    </section>
  );
}
