import React from 'react';
import './Contact.css';
import HeroSectionContact from '../../components/hero_section_contact/HeroSectionContact';
import Header2 from '../../components/header/Header2';
import ContactForm from '../../components/contact_form/ContactForm';
import ContactInfo from '../../components/contact_info/ContactInfo';
import Footer from '../../components/footer/Footer';
import SocialMediaSection from '../../components/social_media_section/SocialMediaSection';
import GoogleMapOffice from '../../components/google_map_office/GoogleMapOffice';
import WhatsappCallBtn from '../../components/whatsapp_call_btn/WhatsappCallBtn';
export default function Contact() {
  return (
   <>
   <Header2/>
   <HeroSectionContact/>
   <ContactForm/>
   <ContactInfo/>
   <SocialMediaSection/>
   <GoogleMapOffice/>
   <WhatsappCallBtn/>
   <Footer/>
   
   
   </>
  )
}
