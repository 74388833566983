import React from 'react';
import './OurMission.css';
import { FaLaptop, FaCogs, FaHandshake, FaBrain } from "react-icons/fa";
export default function OurMission() {
  return (
   <>
   <section className="our-mission">
      <div className="mission-content">
        <h2 className="mission-title">Our Mission</h2>
        <p className="mission-subheading">
          At GlassHopeOne Technologies, we are committed to driving innovation, empowering businesses, and shaping the future with scalable IT solutions.
        </p>

        <div className="mission-items">
          <div className="mission-item">
            <FaLaptop className="mission-icon" />
            <h3 className="mission-heading">Empowering Small & Medium Businesses</h3>
            <p className="mission-text">
              We provide cutting-edge technology that helps small and medium businesses scale and thrive in the digital world.
            </p>
          </div>

          <div className="mission-item">
            <FaCogs className="mission-icon" />
            <h3 className="mission-heading">Scalable & Innovative IT Solutions</h3>
            <p className="mission-text">
              Our IT solutions are designed to grow with your business, ensuring you have the tools you need to succeed.
            </p>
          </div>

          <div className="mission-item">
            <FaHandshake className="mission-icon" />
            <h3 className="mission-heading">Affordability with Quality</h3>
            <p className="mission-text">
              We deliver cost-effective solutions without compromising on quality, ensuring your business receives the best value.
            </p>
          </div>

          <div className="mission-item">
            <FaBrain className="mission-icon" />
            <h3 className="mission-heading">Investing in AI-Driven Solutions</h3>
            <p className="mission-text">
              We are heavily investing in AI technologies to bring future-driven solutions to your business, helping you stay ahead of the curve.
            </p>
          </div>
        </div>
      </div>
    </section>
   
   
   
   
   
   </>
  )
}
