import React from 'react';

import './About.css';
import Header2 from '../../components/header/Header2';
import HeroSectionAbout from '../../components/hero_section_about_us/HeroSectionAbout';
import OurVision from '../../components/our_vision/OurVision';
import OurMission from '../../components/our_mission/OurMission';
import OurCoreService from '../../components/our_core_services/OurCoreService';
import WhyChooseUs from '../../components/why_choose_us/WhyChooseUs';
import CallToActionAbout from '../../components/call_to_action_about/CallToActionAbout';
import Footer from '../../components/footer/Footer';
export default function About() {
  return (
    <>
    <Header2/>
    <HeroSectionAbout/>
    <OurVision/>
    <OurMission/>
    <OurCoreService/>
    <WhyChooseUs/>
    <CallToActionAbout/>
    <Footer/>
    </>
  )
}
