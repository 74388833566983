import React from 'react';
import './Home.css';
import Header2 from '../../components/header/Header2';
import HeroSection from '../../components/herosection/Herosection';
import ShortAbout from '../../components/short_about/ShortAbout';
import ShortServicesOverview from '../../components/short_services_overview/ShortServicesOverview';
import ShortIndustriesWeServe from '../../components/short_industries_we_serve/ShortIndustriesWeServe';
import ShortWhyChooseUS from '../../components/short_why_choose_us/ShortWhyChooseUS';
import ShortTechnologyStack from '../../components/short_technology_stack/ShortTechnologyStack';
import Footer from '../../components/footer/Footer';
import WhatsappCallBtn from '../../components/whatsapp_call_btn/WhatsappCallBtn';
import { Helmet } from 'react-helmet';
export default function Home() {
  return (
    <>

      <Helmet>
        <title>Glasshopeone Technologies Pvt. Ltd. | Best Website and Android App Development Company in Bhubaneswar</title>
        <meta name="description" content="Glasshopeone Technologies Pvt. Ltd. offers top-notch website and Android app development services in Bhubaneswar, Odisha. Partner with us to grow your business with custom web and mobile solutions tailored to your needs." />
        <meta name="keywords" content="website development Bhubaneswar, Android app development Odisha, custom web solutions, professional app developers, business websites, top web developers Odisha, expert Android developers, Glasshopeone Technologies, IT company Bhubaneswar, mobile app solutions, Bhubaneswar web design, glasshopeone,glasshopeone technplogies pvt ltd ,glasshope,best it company in bhubaneswar,best it company India,best software company India, website development,app development,android development,mobile app development,website creation" />
        <meta name="author" content="Glasshopeone Technologies Pvt. Ltd." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.glasshopeone.com/" />
      </Helmet>
      <Header2 />
      <HeroSection />
      <ShortAbout />
      <ShortServicesOverview />
      <ShortIndustriesWeServe />
      <ShortWhyChooseUS />
      <ShortTechnologyStack />
      <WhatsappCallBtn />
      <Footer />
    </>
  )
}
