import React from 'react';
import './Footer.css';
import { Link,  useLocation } from "react-router-dom";

import logo from '../../assets/images/logo_white_transparent.png';

export default function Footer() {
   const location = useLocation();
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Footer Top Section */}
        <div className="footer-top">
          {/* Logo Section */}
          <div className="footer-logo">
            <img src={logo} alt="SARS India Liaison Pvt. Ltd." className="footer-logo-img" />
            <p className="footer-tagline">Glass of Hope for Everyone in the Digital world.</p>
          </div>

          {/* Navigation Links */}
          <div className="footer-links">
            <h4>Quick Links</h4>
            <ul>
              <li><Link to="/about-us" className={location.pathname === '/about-us' ? 'active' : ''}>About</Link></li>
               <Link to="/services" className={`dropdown-link ${location.pathname === '/services' ? 'active' : ''}`}>Services</Link>
              <li> <Link to="/templates" className={`dropdown-link ${location.pathname === '/templates' ? 'active' : ''}`}>Templates</Link></li>
               <li> <Link to="/contact-us" className={`{location.pathname === '/contact-us' ? 'active' : ''}`}>Contact</Link></li>
                <li><Link to="/" className={location.pathname === '/about-us' ? 'active' : ''}>Home</Link></li>
            </ul>
          </div>

          {/* Contact Section */}
          <div className="footer-contact">
            <h4>Contact Us</h4>
            <address>
              <p>Plot No: 9N16, Cowork Venue, 4th Floor, Archbishop's House,<br />Satyanagar, Bhubaneswar, 751007</p>
              <p>Phone: <a href="tel:+919938662786">+91 7852965155</a></p>
              <p>Email: <a href="mailto:contact@sarsindia.com">contact@glasshopeone.com</a></p>
            </address>
            <div className="footer-social">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </div>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} Glasshopeone Technologies Pvt. Ltd.  All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}
