import React from 'react';
import './Templates.css';
import Header2 from '../../components/header/Header2';
import HeroSectionTemplates from '../../components/hero_section_templates/HeroSectionTemplates';
import TemplatesList from '../../components/templates_list/TemplatesList';
import Packages1 from '../../components/packages_section_templates_1/Packages1';
import WhatsappCallBtn from '../../components/whatsapp_call_btn/WhatsappCallBtn';
import Footer from '../../components/footer/Footer';
export default function Templates() {
  return (
   <>
   <Header2/>
   |<HeroSectionTemplates/>
   <TemplatesList/>
   <Packages1/>
   <WhatsappCallBtn/>
   <Footer/>
   
   
   
   
   </>
  )
}
