import React from 'react';
import {
  Link
} from "react-router-dom";
import './ShortIndustriesWeServe.css';
import { FaShoppingCart,FaUserAlt,FaCalendarAlt,FaBriefcase,FaSeedling,FaCogs,FaBug, FaBroom,FaTruckMoving,FaUtensils, FaHeartbeat, FaGraduationCap, FaCamera, FaTruck, FaHotel, FaFilm, FaStethoscope, FaUsers, FaBuilding, FaHeart, FaMoneyBill, FaGlobe, FaBook, FaPaintBrush } from "react-icons/fa";
const industries = [
    { icon: <FaShoppingCart />, title: "E-commerce Businesses", description: "Small retailers, boutiques, grocery delivery services." },
    { icon: <FaBuilding />, title: "Real Estate", description: "Realtors, property management services." },
    { icon: <FaTruck />, title: "Logistics & Delivery", description: "Courier services, freight solutions." },
    { icon: <FaUtensils />, title: "Food & Beverage", description: "Restaurants, cafés, food delivery startups." },
    { icon: <FaHeartbeat />, title: "Health & Fitness", description: "Gyms, fitness studios, wellness centers." },
    { icon: <FaGraduationCap />, title: "Education & Training", description: "Coaching centers, schools, online learning platforms." },
    { icon: <FaCamera />, title: "Professional Services", description: "Photographers, event planners, real estate agents." },
    { icon: <FaHotel />, title: "Travel & Tourism", description: "Hotels, homestays, travel agencies." },
    { icon: <FaFilm />, title: "Entertainment", description: "Cinemas, theaters, musicians, event management." },
    { 
        icon: <FaTruckMoving />, 
        title: "Packer & Mover", 
        description: "Moving services for households and offices, including packing and relocation." 
      },
      { 
        icon: <FaBroom />, 
        title: "House Cleaning", 
        description: "Professional cleaning services for homes, offices, and commercial spaces." 
      },
      { 
        icon: <FaBug />, 
        title: "Pest Control", 
        description: "Pest management and extermination services for homes and businesses." 
      },
      { 
        icon: <FaCogs />, 
        title: "Interior Designer", 
        description: "Design and decorate interiors for residential and commercial spaces." 
      },
      { 
        icon: <FaBriefcase />, 
        title: "Business Consultant", 
        description: "Providing expert advice and solutions for business growth, strategy, and operations." 
      },
      { 
        icon: <FaSeedling />, 
        title: "Gardening Nursery", 
        description: "Offering a variety of plants, gardening tools, and expert gardening advice." 
      },
      { 
        icon: <FaCalendarAlt />, 
        title: "Event Management", 
        description: "Organizing events such as weddings, corporate events, and parties with end-to-end solutions." 
      },
      { 
        icon: <FaUserAlt />, 
        title: "Freelancers in Various Fields", 
        description: "Offering freelance services across various fields like writing, graphic design, web development, etc." 
      },
    { icon: <FaStethoscope />, title: "Healthcare", description: "Clinics, pharmacies, healthcare services." },
    { icon: <FaUsers />, title: "Beauty & Wellness", description: "Salons, spas, wellness centers." },
    { icon: <FaHeart />, title: "NGOs & Charities", description: "Apps for donations and volunteer management." },
    { icon: <FaMoneyBill />, title: "Finance & Investment", description: "Stock traders, small lending agencies." },
    { icon: <FaGlobe />, title: "Local Community & Associations", description: "Clubs, societies, resident welfare associations." },
    { icon: <FaBook />, title: "Custom Hobby & Interest Groups", description: "Book clubs, gaming communities, pet care groups." },
    { icon: <FaPaintBrush />, title: "Freelancers & Solopreneurs", description: "Artists, fitness trainers, and other solopreneurs." },
  ];
export default function ShortIndustriesWeServe() {
  return (
    <>
     <section className="industries-section">
      <h2 className="industries-title">Industries We Serve</h2>
      <p className="industries-subtitle">Tailored app development solutions for diverse businesses and professionals.</p>
      <div className="industries-grid">
        {industries.map((industry, index) => (
          <div key={index} className="industry-card">
            <div className="icon-container">{industry.icon}</div>
            <h3 className="industry-title">{industry.title}</h3>
            <p className="industry-description">{industry.description}</p>
          </div>
        ))}
      </div>
      <div className="industries-cta">
        <Link to="/contact-us" className="cta-button">Contact Us to Get Started</Link>
      </div>
    </section>
    
    
    
    
    
    </>
  )
}
