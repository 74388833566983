import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Services from './pages/Services/Services';
import About from './pages/About/About';
import Templates from './pages/Templates/Templates';
const router = createBrowserRouter([
  {
    path: "/",
    element:<Home/>
  },
  {
    path:"/contact-us",
    element:<Contact/>
  },
  {
    path:"/services",
    element:<Services/>
  },
  {
    path:"/about-us",
    element:<About/>
  },
  {
    path:"/templates",
    element:<Templates/>
  }
  

]);

function App() {
  return (
   <>
   
   <RouterProvider router={router} />
   
   
   
   
   
   </>
  );
}

export default App;
