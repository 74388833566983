import React from 'react';
import './HeroSectionServices.css';
import { FaCode, FaAndroid, FaTools } from 'react-icons/fa'; // Using icons for services

export default function HeroSectionServices() {
  return (
    <header className="header-section_rr7">
      <div className="header-content_rr7">
        <h1 className="header-title_rr7">Our Services</h1>
        <p className="header-subtitle_rr7">
          Explore our specialized solutions for web development and Android app development.
        </p>

        {/* Service Icons Section */}
        <div className="service-icons_rr7">
          <div className="service-icon">
            <FaCode size={40} className="service-icon-img" />
            <p className="service-icon-text">Web Development</p>
          </div>
          <div className="service-icon">
            <FaAndroid size={40} className="service-icon-img" />
            <p className="service-icon-text">Android App Development</p>
          </div>
          <div className="service-icon">
            <FaTools size={40} className="service-icon-img" />
            <p className="service-icon-text">Tech Support</p>
          </div>
        </div>
      </div>
    </header>
  );
}
