import React from 'react';
import './ContactInfo.css';
import { Mail, Phone, MapPin, Clock } from "lucide-react";
export default function ContactInfo() {
  return (
   <>
   <section className="contact-info">
      <div className="contact-info-container">
        {/* Contact Card - Email */}
        <div className="contact-card">
          <Mail className="contact-icon" />
          <h3>Email Us</h3>
          <p>info@glasshopeone.com</p>
        </div>

        {/* Contact Card - Phone */}
        <div className="contact-card">
          <Phone className="contact-icon" />
          <h3>Call Us</h3>
          <p>+91 7852965155</p>
        </div>

        {/* Contact Card - Address */}
        <div className="contact-card">
          <MapPin className="contact-icon" />
          <h3>Visit Our Office</h3>
          <p>PlotNo:9N16,4th Floor,Cowork Venue,Arch Bishop's House ,Satyanagar, Bhubaneswar,751007, Odisha, India</p>
        </div>

        {/* Contact Card - Office Hours */}
        <div className="contact-card">
          <Clock className="contact-icon" />
          <h3>Office Hours</h3>
          <p>Mon - Sat: 10:30 AM - 7:30 PM</p>
        </div>
      </div>
    </section>
   
   
   
   
   </>
  )
}
