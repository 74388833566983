import React from 'react';
import './Packages1.css';
export default function Packages1() {
    const packages = [
        {
          title: "Basic Package",
          price: "₹10,000",
          features: [
            "1 Custom Page",
            "4Sections",
            "Responsive Design",
            "Basic SEO Optimization",
            "1 Year Shared Hosting Free",
            "*+ 18% GST"
          ],
          link: "https://basic.glasshopeone.com/",
        },
        {
          title: "Silver Package",
          price: "₹17,000",
          features: [
            "4 Custom Pages",
            "Home,About,Services,Contact",
            "Responsive Design",
            "SEO Optimization",
            "1 Year Shared Hosting Free",
            "*+ 18% GST"
          ],
          link: "https://silver.glasshopeone.com/",
        },
        {
          title: "Gold Package",
          price: "₹25,000",
          features: [
            "5 Custom Pages",
            "Home,About,Services,Gallery,Contact ",
            "Testimonials in Home Page",
            "Clien's Logo Section in Home Page",
            "Youtube Video Integration",
            "Advanced Responsive Design",
            "Comprehensive SEO",
           "1 Year Dedicated Hosting Free",
           "*+ 18% GST"
          ],
          link: "https://gold.glasshopeone.com/",
        },
      ];
  return (
    <>
     <section className="packages-section" id='packages-123'>
      <h2 className="section-title">Our Packages</h2>
      <div className="packages-container">
        {packages.map((pkg, index) => (
          <div className="package-card" key={index}>
            <h3 className="package-title">{pkg.title}</h3>
            <p className="package-price">{pkg.price}</p>
            <ul className="features-list">
              {pkg.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            {/* <a href={pkg.link} className="view-template-btn">
              View Template
            </a> */}
          </div>
        ))}
      </div>
    </section>
    
    
    
    
    
    
    </>
  )
}
