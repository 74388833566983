import React from 'react';
import { FaLaptopCode, FaAndroid } from "react-icons/fa"; // Icons for services
import './ShortServicesOverview.css';
export default function ShortServicesOverview() {
  return (
    <>
      <section className="services-overview">
      <div className="container">
        {/* Section Header */}
        <div className="section-header">
          <h2>Our Services</h2>
          <p>
            Empowering businesses with innovative and tailored technology solutions. Explore our core services designed to meet your unique needs.
          </p>
        </div>

        {/* Services Cards */}
        <div className="services-grid">
          {/* Web Development */}
          <div className="service-card">
            <div className="icon-container">
              <FaLaptopCode className="service-icon" />
            </div>
            <h3>Web Development</h3>
            <p>
              Build modern, responsive, and dynamic websites that enhance your online presence and elevate user experiences.
            </p>
          </div>

          {/* Android App Development */}
          <div className="service-card">
            <div className="icon-container">
              <FaAndroid className="service-icon" />
            </div>
            <h3>Android App Development</h3>
            <p>
              Develop feature-rich, native Android apps tailored to your business needs, ensuring scalability and performance.
            </p>
          </div>
        </div>
      </div>
    </section>
    
    
    
    
    
    </>
  )
}
