import React from 'react';
import './Services.css';
import Header from '../../components/header/Header2';
import HeroSectionServices from '../../components/hero_section_services/HeroSectionServices';
import WebsiteDevelopmentService from '../../components/website_development_service/WebsiteDevelopmentService';
import TechStackWebDevelopment from '../../components/tech_stack_web_development/TechStackWebDevelopment';
import AndroidAppDevelopmentService from '../../components/android_app_development_service/AndroidAppDevelopmentService';
import TechStackAndroidDevelopment from '../../components/tech_stack_android_development/TechStackAndroidDevelopment';
import Footer from '../../components/footer/Footer';
import WhatsappCallBtn from '../../components/whatsapp_call_btn/WhatsappCallBtn';
export default function Services() {
  return (
   <>
   <Header/>
   <HeroSectionServices/>
   <TechStackWebDevelopment/>
   <WebsiteDevelopmentService/>
   <TechStackAndroidDevelopment/>
   <AndroidAppDevelopmentService/>
   <WhatsappCallBtn/>
   <Footer/>
   
   </>
  )
}
