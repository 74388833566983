import React from 'react';
import './AndroidAppDevelopmentService.css';
import { FaCheckCircle, FaMobileAlt, FaDatabase, FaShieldAlt, FaClock, FaStore, FaCogs } from "react-icons/fa";

const packages = [
    {
      name: "Basic",
      priceINR: "₹50,000 - ₹80,000",
      priceUSD: "$1,000 - $2,000",
      suitableFor: "Startups, Small Businesses",
      features: [
        { icon: <FaMobileAlt />, text: "Basic UI/UX Design" },
        { icon: <FaCheckCircle />, text: "Up to 5 Screens" },
        { icon: <FaStore />, text: "Google Play Store Setup" },
        { icon: <FaShieldAlt />, text: "Basic Security" },
        { icon: <FaDatabase />, text: "Basic Local Database" },
        { icon: <FaClock />, text: "4-6 Weeks Delivery" }
      ],
      colorClass: "basic",
    },
    {
      name: "Silver",
      priceINR: "₹1,00,000 - ₹1,50,000",
      priceUSD: "$3,000 - $5,000",
      suitableFor: "Mid-Sized Businesses",
      features: [
        { icon: <FaMobileAlt />, text: "Semi-Custom UI/UX Design" },
        { icon: <FaCheckCircle />, text: "Up to 10 Screens" },
        { icon: <FaStore />, text: "Play Store Optimization" },
        { icon: <FaShieldAlt />, text: "Enhanced Security" },
        { icon: <FaDatabase />, text: "Cloud Database Integration" },
        { icon: <FaClock />, text: "6-10 Weeks Delivery" }
      ],
      colorClass: "silver",
    },
    {
      name: "Gold",
      priceINR: "₹2,00,000 - ₹3,50,000",
      priceUSD: "$7,000 - $9,000",
      suitableFor: "Growing Businesses",
      features: [
        { icon: <FaMobileAlt />, text: "Full Custom UI/UX Design" },
        { icon: <FaCheckCircle />, text: "Up to 15 Screens" },
        { icon: <FaStore />, text: "Play Store ASO" },
        { icon: <FaShieldAlt />, text: "Advanced Security" },
        { icon: <FaDatabase />, text: "Advanced Cloud DB Setup" },
        { icon: <FaClock />, text: "8-16 Weeks Delivery" }
      ],
      colorClass: "gold",
    },
    {
      name: "Diamond",
      priceINR: "₹4,00,000 - ₹6,00,000+",
      priceUSD: "$11,000 - $15,000+",
      suitableFor: "Enterprises, Large Businesses",
      features: [
        { icon: <FaMobileAlt />, text: "Advanced Custom UI/UX" },
        { icon: <FaCheckCircle />, text: "As Required" },
        { icon: <FaStore />, text: "Advanced Play Store ASO" },
        { icon: <FaShieldAlt />, text: "Comprehensive Security" },
        { icon: <FaDatabase />, text: "Full Cloud-Based Solution" },
        { icon: <FaClock />, text: "16-24 Weeks Delivery" }
      ],
      colorClass: "diamond",
    },
    {
      name: "Custom",
      priceINR: "Based on Requirements",
      priceUSD: "As Agreed",
      suitableFor: "Unique Business Needs",
      features: [
        { icon: <FaCogs />, text: "Fully Customized UI/UX" },
        { icon: <FaCheckCircle />, text: "As Required" },
        { icon: <FaStore />, text: "Full Play Store Management" },
        { icon: <FaShieldAlt />, text: "Tailored Security Solutions" },
        { icon: <FaDatabase />, text: "Custom Database & Backend" },
        { icon: <FaClock />, text: "As Agreed" }
      ],
      colorClass: "custom",
    },
];

export default function AndroidAppDevelopmentService() {
  return (
    <section className="android-packages">
      <h2>Android Development Packages</h2>
      <div className="package-grid">
        {packages.map((pkg, index) => (
          <div key={index} className={`package-card ${pkg.colorClass}`}>
            <h3>{pkg.name}</h3>
            <p className="suitable-for">{pkg.suitableFor}</p>
            <div className="pricing">
              <div className="price-inr">INR: <span>{pkg.priceINR}</span></div>
              <div className="price-usd">USD: <span>{pkg.priceUSD}</span></div>
            </div>
            <ul className="features">
              {pkg.features.map((feature, i) => (
                <li key={i}>{feature.icon} {feature.text}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
}
