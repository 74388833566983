import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon from react-icons
import { FaPhoneAlt } from 'react-icons/fa'; // Import Call icon from react-icons
import './WhatsappCallBtn.css';

export default function WhatsappCallBtn() {
  return (
    <>
      <div className="floating-buttons">
        {/* Call Button (Left) */}
        <a href="tel:+917852965155" className="floating-button call">
          <FaPhoneAlt size={30} /> {/* Call Icon */}
        </a>

        {/* WhatsApp Button (Right) */}
        <a href="https://wa.me/7852965155" className="floating-button whatsapp">
          <FaWhatsapp size={30} /> {/* WhatsApp Icon */}
        </a>
      </div>
    </>
  );
}
