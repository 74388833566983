import React from "react";
import { MessageSquare, MapPin, Users, Mail } from "lucide-react"; // Icons for modern appeal
import "./HeroSectionContact.css";

export default function HeroSectionContact() {
  return (
    <header className="contact-header">
      <div className="header-container">
        {/* Floating Icons Section */}
        <div className="floating-icons">
          <div className="icon-box">
            <MessageSquare className="icon" />
            <span>Let's Talk</span>
          </div>
          <div className="icon-box">
            <MapPin className="icon" />
            <span>Locate Us</span>
          </div>
          <div className="icon-box">
            <Users className="icon" />
            <span>Our Team</span>
          </div>
          <div className="icon-box">
            <Mail className="icon" />
            <span>Stay Connected</span>
          </div>
        </div>

        {/* Main Header Text */}
        <div className="header-text">
          <h1 className="header-title">Let’s Connect</h1>
          <p className="header-subtitle">
            Whether you have a question, an idea, or just want to say hello, we'd love to hear from you!
          </p>
        </div>
      </div>
    </header>
  );
}
