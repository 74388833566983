import React from 'react';
import './OurVision.css';
import { FaGlobe, FaRobot, FaDollarSign } from "react-icons/fa";
export default function OurVision() {
  return (
   <>
   <section className="our-vision">
      <div className="vision-content">
        <h2 className="vision-title">Our Vision</h2>
        <p className="vision-subheading">
          GlassHopeOne Technologies aims to be a global leader in IT, with a vision to innovate and evolve with emerging technologies, benefiting businesses and humanity.
        </p>

        <div className="vision-items">
          <div className="vision-item">
            <FaGlobe className="vision-icon" />
            <h3 className="vision-heading">Global Leadership</h3>
            <p className="vision-text">
              We strive to become a global leader in IT, offering cutting-edge solutions across the world.
            </p>
          </div>

          <div className="vision-item">
            <FaRobot className="vision-icon" />
            <h3 className="vision-heading">AI for Humanity</h3>
            <p className="vision-text">
              We are committed to exploring and integrating Artificial Intelligence for the greater good of humanity.
            </p>
          </div>

          <div className="vision-item">
            <FaDollarSign className="vision-icon" />
            <h3 className="vision-heading">Affordable IT Solutions</h3>
            <p className="vision-text">
              Our goal is to deliver affordable IT solutions worldwide, empowering businesses to grow and succeed.
            </p>
          </div>
        </div>
      </div>
    </section>
   
   
   
   
   
   </>
  )
}
