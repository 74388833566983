import React from 'react';
import './GoogleMapOffice.css';
export default function GoogleMapOffice() {
   
  return (
    <>
      <div className="map-container">
      <h2>Our Office Location</h2>
      <iframe
        title="Office Location"
        width="100%"
        height="400"
        src="https://www.google.com/maps?q=GlassHopeOne+Technologies,+Satyanagar,+Bhubaneswar&output=embed"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
    
    
    
    
    
    </>
  )
}
