import React from 'react';
import './ShortWhyChooseUS.css';
import { FaRegLightbulb, FaRocket, FaUsers, FaShieldAlt } from 'react-icons/fa';
const reasons = [
    {
      icon: <FaRegLightbulb className="icon_oo9" />,
      title: 'Innovative Solutions',
      description: 'We stay ahead of the curve by leveraging the latest technologies to create custom solutions for your business.'
    },
    {
      icon: <FaRocket className="icon_oo9" />,
      title: 'Fast & Efficient',
      description: 'Our agile development approach ensures faster delivery and quicker time-to-market for your product.'
    },
    {
      icon: <FaUsers className="icon_oo9" />,
      title: 'Client-Centric Approach',
      description: 'We put our clients first, ensuring transparent communication, reliable support, and tailored solutions for every need.'
    },
    {
      icon: <FaShieldAlt className="icon_oo9" />,
      title: 'Security First',
      description: 'Your data’s security is our priority. We implement best practices in security and compliance to safeguard your information.'
    }
  ];
export default function ShortWhyChooseUS() {
  return (
   <>
    <section className="why-choose-us_oo9">
      <div className="container_oo9">
        <h2>Why Choose Us?</h2>

        {/* USP - Unique Selling Proposition */}
        <div className="usp_oo9">
          <h3>Glass of Hope for Everyone in the Digital World</h3>
          <p>We offer affordable pricing with high-end services and products, empowering businesses to succeed in the digital landscape.</p>
        </div>

        <div className="reasons_oo9">
          {reasons.map((reason, index) => (
            <div className="reason-card_oo9" key={index}>
              <div className="icon-container_oo9">{reason.icon}</div>
              <h3>{reason.title}</h3>
              <p>{reason.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
   
   
   
   
   </>
  )
}
