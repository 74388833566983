import React from 'react';
import './ShortAbout.css';
export default function ShortAbout() {
  return (
    <section className="about-section">
      <div className="container">
        {/* Section Header */}
        <div className="about-header">
          <h2>About Glasshopeone Technologies Pvt. Ltd.</h2>
          <p>
            Shaping the future of technology with innovative solutions, GlassHopeOne is committed to helping businesses thrive in a digital-first world.
          </p>
        </div>

        {/* Mission and Vision Section */}
        <div className="about-content">
          <div className="about-card">
            <h3>Our Mission</h3>
            <p>
              To empower startups and small businesses with modern web and app development services, enabling them to harness technology for growth and innovation.
            </p>
            <h3>Our Vision</h3>
            <p>
              To be a trusted partner in digital transformation, delivering solutions that drive success in today’s fast-paced world.
            </p>
          </div>

          {/* Core Values Section */}
          <div className="about-card values-card">
            <h3>Our Core Values</h3>
            <ul>
              <li>
                <strong>Innovation:</strong> Constantly exploring new technologies to deliver cutting-edge solutions.
              </li>
              <li>
                <strong>Quality:</strong> Ensuring every project meets the highest standards of excellence.
              </li>
              <li>
                <strong>Client Focus:</strong> Building strong relationships by understanding and addressing client needs.
              </li>
              <li>
                <strong>Integrity:</strong> Conducting business with transparency and honesty.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
