import React, { useState } from "react";
import './ContactForm.css';
import { Mail, User, Smartphone, MessageCircle, Send, List } from "lucide-react";
export default function ContactForm() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        service: "",
        message: "",
      });
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Submitted", formData);
        // Here you can handle API submission
      };
  return (
   <>
   <div className="contact-form-container">
      <h2 className="contact-title">Let's Work Together</h2>
      <p className="contact-subtitle">Fill in the details and we'll get back to you soon!</p>

      <form className="contact-form" onSubmit={handleSubmit}>
        {/* Name Field */}
        <div className="input-group">
          <User className="input-icon" />
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        {/* Email Field */}
        <div className="input-group">
          <Mail className="input-icon" />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        {/* Phone Field */}
        <div className="input-group">
          <Smartphone className="input-icon" />
          <input
            type="tel"
            name="phone"
            placeholder="Your Phone Number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        {/* Service Inquiry Dropdown */}
        <div className="input-group">
          <List className="input-icon" />
          <select name="service" value={formData.service} onChange={handleChange} required>
            <option value="">Select a Service</option>
            <option value="Website Development">Website Development</option>
            <option value="Android App Development">Android App Development</option>
            <option value="UI/UX Design">UI/UX Design</option>
            <option value="Digital Marketing">Digital Marketing</option>
          </select>
        </div>

        {/* Message Field */}
        <div className="input-group">
          <MessageCircle className="input-icon" />
          <textarea
            name="message"
            placeholder="Your Message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>

        {/* Submit Button */}
        <button type="submit" className="submit-button">
          <Send className="send-icon" />
          Send Message
        </button>
      </form>
    </div>
   
   
   
   
   </>
  )
}
