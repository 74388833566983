import React from 'react';
import './WhyChooseUs.css';
import { FaCheckCircle } from "react-icons/fa";
export default function WhyChooseUs() {
  return (
   <>
   <section className="why-choose-us_gg5">
      <div className="why-content_gg5">
        <h2 className="why-title_gg5">Why Choose Us?</h2>
        <p className="why-description_gg5">
          GlassHopeOne Technologies offers a competitive edge through affordable IT solutions, cutting-edge AI integration, and a customer-first approach. Here’s why businesses trust us.
        </p>

        <div className="why-grid_gg5">
          <div className="why-item_gg5">
            <FaCheckCircle className="check-icon_gg5" />
            <h3 className="item-heading_gg5">Affordable Yet Premium IT Solutions</h3>
            <p className="item-text_gg5">
              We deliver top-quality technology solutions at prices that small businesses can afford, without compromising performance or scalability.
            </p>
          </div>

          <div className="why-item_gg5">
            <FaCheckCircle className="check-icon_gg5" />
            <h3 className="item-heading_gg5">AI-Driven Innovation for Small Businesses</h3>
            <p className="item-text_gg5">
              Our AI-powered solutions help small businesses grow by automating processes, enhancing customer engagement, and providing insights for better decision-making.
            </p>
          </div>

          <div className="why-item_gg5">
            <FaCheckCircle className="check-icon_gg5" />
            <h3 className="item-heading_gg5">Customer-First Approach with Tailored Solutions</h3>
            <p className="item-text_gg5">
              Every business is unique, and we prioritize understanding your goals, offering customized solutions that align with your specific needs and objectives.
            </p>
          </div>
        </div>
      </div>
    </section>
   
   
   
   
   
   </>
  )
}
