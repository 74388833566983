import React, { useState } from 'react';
import './Header2.css';
import logo_sarsindialiaison from '../../assets/images/logo_white_transparent.png';
import {
  Link, useLocation
} from "react-router-dom";
import { Menu, X } from 'lucide-react';

export default function Header2() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  return (
    <header className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src={logo_sarsindialiaison} alt="Company Logo" className="logo" />
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </div>
        <nav className={`navbar-nav ${isMenuOpen ? 'active' : ''}`}>
          <ul className="nav-list">
            <li><Link to="/" className={location.pathname === '/about-us' ? 'active' : ''}  onClick={closeMenu}>Home</Link></li>
            <li><Link to="/about-us" className={location.pathname === '/about-us' ? 'active' : ''}  onClick={closeMenu}>About</Link></li>
            <li className="dropdown">
            <Link to="/services" className={`dropdown-link ${location.pathname === '/services' ? 'active' : ''}`}  onClick={closeMenu}>Services</Link>
             
              <ul className="dropdown-menu">
                {/* Add your dropdown items here */}
              </ul>
            </li>
            <li> <Link to="/templates" className={`dropdown-link ${location.pathname === '/templates' ? 'active' : ''}`}  onClick={closeMenu}>Templates</Link></li>
            <li> <Link to="/contact-us" className={`{location.pathname === '/contact-us' ? 'active' : ''}`}  onClick={closeMenu}>Contact</Link></li>
          </ul>
        </nav>
        <div className="navbar-search">
          <input type="text" placeholder="Search..." />
        </div>
      </div>
    </header>
  );
}
