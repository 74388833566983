import React from 'react';
import './OurCoreService.css';
import { FaLaptopCode, FaMobileAlt, FaCloud } from "react-icons/fa";
export default function OurCoreService() {
  return (
    <>
     <section className="core-services">
      <div className="services-content">
        <h2 className="services-title">Our Core Services & Expertise</h2>
        <p className="services-description">
          At Glasshopeone Technologies, we offer scalable, modern, and affordable solutions to empower businesses through cutting-edge technology.
        </p>

        <div className="services-items">
          <div className="services-item">
            <FaLaptopCode className="service-icon" />
            <h3 className="service-heading">Website Development</h3>
            <p className="service-text">
              We build scalable, modern, and affordable websites tailored to meet your business goals and grow with your company.
            </p>
          </div>

          <div className="services-item">
            <FaMobileAlt className="service-icon" />
            <h3 className="service-heading">Mobile App Development</h3>
            <p className="service-text">
              Our Android apps are designed with cutting-edge technologies, including AI integration, to elevate user experiences.
            </p>
          </div>

          <div className="services-item">
            <FaCloud className="service-icon" />
            <h3 className="service-heading">Cloud & AI Solutions</h3>
            <p className="service-text">
              We provide future-ready cloud and AI solutions to help businesses stay competitive in an ever-evolving digital landscape.
            </p>
          </div>
        </div>
      </div>
    </section>
    
    
    
    </>
  )
}
