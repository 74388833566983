import React from 'react';
import {
  Link
} from "react-router-dom";
import './HeroSectionAbout.css';
import backgroundImage from '../../assets/images/background_about.jpg'; // Import the image
export default function HeroSectionAbout() {
  return (
   <>
    <section className="hero_ff5" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="hero-content_ff5">
        <h1 className="hero-title_ff5">Glasshopeone Technologies Pvt. Ltd., Bhubaneswar</h1>
        <p className="hero-tagline_ff5">Glass of Hope for everyone in the digital world</p>
        <p className="hero-subheading_ff5">
          Providing affordable IT solutions for small-to-medium businesses and startups.
        </p>
        <Link to="/services" className="cta-btn_ff5">Discover More</Link>
      </div>
    </section>
   
   
   
   
   
   </>
  )
}
