import React from 'react';
import './WebsiteDevelopmentService.css';

const packages = [
    {
      name: "Basic",
      priceINR: "₹25,000 - ₹50,000",
      priceUSD: "$450 - $1000",
      pages: "Up to 5 Pages",
      seo: "Basic SEO",
      ecom: "❌",
      security: "Basic Security",
      support: "1 Month Support",
      delivery: "3-4 Weeks",
      featured: false,
    },
    {
      name: "Silver",
      priceINR: "₹75,000 - ₹1,50,000",
      priceUSD: "$1500 - $2500",
      pages: "Up to 10 Pages",
      seo: "Enhanced SEO",
      ecom: "Optional Add-on",
      security: "Enhanced Security",
      support: "3 Months Support",
      delivery: "4-8 Weeks",
      featured: false,
    },
    {
      name: "Gold",
      priceINR: "₹1,75,000 - ₹3,00,000",
      priceUSD: "$3500 - $4500",
      pages: "Up to 15 Pages",
      seo: "Advanced SEO",
      ecom: "✅",
      security: "Advanced Security",
      support: "3 Months Support",
      delivery: "8-12 Weeks",
      featured: true,
    },
    {
      name: "Diamond",
      priceINR: "₹3,50,000 - ₹5,00,000",
      priceUSD: "$5000 - $8500",
      pages: "As Required",
      seo: "Full SEO Strategy",
      ecom: "✅",
      security: "Comprehensive Security",
      support: "3 Months Support",
      delivery: "16-20 Weeks",
      featured: false,
    },
    {
      name: "Custom",
      priceINR: "Based on Requirements",
      priceUSD: "As Agreed",
      pages: "As Required",
      seo: "As Required",
      ecom: "✅",
      security: "Tailored Security",
      support: "Custom Support Plans",
      delivery: "As Agreed",
      featured: false,
    },
];

export default function WebsiteDevelopmentService() {
  return (
    <div className="pricing-container">
      <h2 className="pricing-title">Website Development Packages</h2>
      <p className="pricing-subtitle">Select the best package that fits your business needs.</p>

      <div className="pricing-grid">
        {packages.map((pkg, index) => (
          <div key={index} className={`pricing-card ${pkg.featured ? "featured" : ""}`}>
            <h3 className="pricing-name">{pkg.name}</h3>
            <p className="pricing-price">{pkg.priceINR}</p>
            <p className="pricing-price-usd">{pkg.priceUSD}</p>

            <ul className="pricing-features">
              <li>📄 <strong>Pages:</strong> {pkg.pages}</li>
              <li>🔍 <strong>SEO:</strong> {pkg.seo}</li>
              <li>🛒 <strong>E-Commerce:</strong> {pkg.ecom}</li>
              <li>🔒 <strong>Security:</strong> {pkg.security}</li>
              <li>🛠️ <strong>Support:</strong> {pkg.support}</li>
              <li>⏳ <strong>Delivery:</strong> {pkg.delivery}</li>
            </ul>

           
          </div>
        ))}
      </div>
    </div>
  );
}
