import React from "react";
import "./TechStackWebDevelopment.css";
import { FaReact, FaNodeJs, FaPhp, FaAws, FaJava, FaJs } from "react-icons/fa";
import { SiMysql, SiMongodb, SiHostinger, SiFirebase } from "react-icons/si";

const techs = [
  { name: "React", icon: <FaReact className="react" />, color: "#61DBFB" },
  { name: "Node.js", icon: <FaNodeJs className="node" />, color: "#68A063" },
  { name: "PHP", icon: <FaPhp className="php" />, color: "#787CB5" },
  { name: "MySQL", icon: <SiMysql className="mysql" />, color: "#00758F" },
  { name: "MongoDB", icon: <SiMongodb className="mongodb" />, color: "#4DB33D" },
  { name: "AWS", icon: <FaAws className="aws" />, color: "#FF9900" },
  { name: "Hostinger", icon: <SiHostinger className="hostinger" />, color: "#673AB7" },
  { name: "Firebase", icon: <SiFirebase className="firebase" />, color: "#FFCA28" },
  { name: "JavaScript", icon: <FaJs className="javascript" />, color: "#F7DF1E" },
  { name: "Java", icon: <FaJava className="java" />, color: "#007396" },
];

export default function TechStack() {
  return (
    <section className="tech-stack">
      <h2>Our Web Development Tech Stack</h2>
      <div className="tech-grid">
        {techs.map((tech, index) => (
          <div key={index} className="tech-card" style={{ borderColor: tech.color }}>
            <div className="tech-icon">{tech.icon}</div>
            <p>{tech.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
