import React from 'react';
import './HeroSectionTemplates.css';
export default function HeroSectionTemplates() {
  return (
    <>
    <div className="header-section_ee45">
      <div className="header-content_ee45">
        <h1 className="header-title_ee45">Our Website & Android AppTemplates</h1>
        <p className="header-subtitle_ee45">
          Browse through our collection of ready-to-use website templates, tailored for various industries.
        </p>
        <p className="header-description_ee45">
          Each template is designed to be flexible, customizable, and responsive to meet the unique needs of your business.
        </p>
        <button className="cta-button_ee45" >Explore Templates</button>
      </div>
      {/* <div className="header-image_ee45">
        <img 
          src="https://via.placeholder.com/600x400" // Replace with your actual image URL
          alt="Website Templates Illustration" 
          className="header-illustration_ee45" 
        />
      </div> */}
    </div>
    
    
    
    
    </>
  )
}
