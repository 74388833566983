import React from 'react';
import { FaEye, FaExpand } from "react-icons/fa";
import './TemplatesList.css';

const templates = [
    {
        id: 1,
        name: "Pest Control Template",
        description: "A professional template for pest control businesses.",
        url: "https://gold2.glasshopeone.com/",
    },
    {
        id: 2,
        name: "Packer Movers Template",
        description: "Perfect for logistics and moving service providers.",
        url: "https://gold.glasshopeone.com/",
    },
];

export default function TemplatesList() {
    return (
        <section className="template-grid_js6">
            <h2>Business Website Templates</h2>
            <p>Browse our ready-to-use templates for various industries.</p>

            <div className="grid_js6">
                {templates.map((template) => (
                    <div className="card_js6" key={template.id}>
                        {/* Responsive iframe for live preview */}
                        <div className="iframe-container">
                            <iframe src={template.url} title={template.name} loading="lazy"></iframe>
                        </div>
                        <h3>{template.name}</h3>
                        <p>{template.description}</p>

                        <div className="button-group">
                            <a href={template.url} target="_blank" rel="noopener noreferrer" className="view-btn_js6">
                                <FaEye className="icon" /> View Full Template
                            </a>
                            <button className="fullscreen-btn" onClick={() => window.open(template.url, '_blank')}>
                                <FaExpand className="icon" /> Fullscreen Preview
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
