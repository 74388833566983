import React from 'react';
import './TechStackAndroidDevelopment.css';
import { FaJava, FaAndroid, FaDatabase, FaCloud, FaLock, FaBell, FaCogs, FaChartLine, FaStore } from 'react-icons/fa';
const techStack = [
    { name: "Java", icon: <FaJava />, description: "Core programming language for Android development." },
    { name: "Android Studio", icon: <FaAndroid />, description: "Official IDE for building Android applications." },
    { name: "Firebase", icon: <FaCloud />, description: "Cloud backend for authentication, storage, and database." },
    { name: "MySQL & PHP", icon: <FaDatabase />, description: "Relational database and backend scripting." },
    { name: "Google Play Console", icon: <FaStore />, description: "Platform for publishing and managing Android apps." },
    { name: "Security & Authentication", icon: <FaLock />, description: "Firebase Authentication, Google Sign-In, OTP Verification." },
    { name: "Push Notifications", icon: <FaBell />, description: "Firebase Cloud Messaging (FCM) for real-time notifications." },
    { name: "API Integrations", icon: <FaCogs />, description: "RESTful APIs, Google APIs (Maps, Payments, etc.)." },
    { name: "Analytics & Monitoring", icon: <FaChartLine />, description: "Google Analytics, Firebase Crashlytics, Performance Monitoring." },
];

export default function TechStackAndroidDevelopment() {
  return (
    <>
     <section className="android-stack">
            <h2>Android App Development Tech Stack</h2>
            <div className="stack-grid">
                {techStack.map((tech, index) => (
                    <div key={index} className="stack-card">
                        <div className="icon">{tech.icon}</div>
                        <h3>{tech.name}</h3>
                        <p>{tech.description}</p>
                    </div>
                ))}
            </div>
        </section>
    
    
    
    
    </>
  )
}
