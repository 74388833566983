import React from 'react';
import './SocialMediaSection.css';
import { FaLinkedin, FaInstagram, FaFacebook, FaTwitter, FaGlobe } from "react-icons/fa"; // Importing icons
export default function SocialMediaSection() {
  return (
   <>
   <section className="social-links">
      <h2>Connect with Us</h2>
      <p>Follow us on social media to stay updated.</p>
      <div className="social-icons">
        <a href="https://www.linkedin.com/company/glasshopeone" target="_blank" rel="noopener noreferrer" className="social-icon linkedin">
          <FaLinkedin />
        </a>
        <a href="https://www.instagram.com/glasshopeone" target="_blank" rel="noopener noreferrer" className="social-icon instagram">
          <FaInstagram />
        </a>
        <a href="https://www.facebook.com/glasshopeone" target="_blank" rel="noopener noreferrer" className="social-icon facebook">
          <FaFacebook />
        </a>
        <a href="https://twitter.com/glasshopeone" target="_blank" rel="noopener noreferrer" className="social-icon twitter">
          <FaTwitter/>
        </a>
        <a href="https://glasshopeone.com" target="_blank" rel="noopener noreferrer" className="social-icon website">
          <FaGlobe />
        </a>
      </div>
    </section>
   
   
   
   
   
   </>
  )
}
