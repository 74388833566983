import React from 'react';
import {
  Link
} from "react-router-dom";
import './CallToActionAbout.css';
export default function CallToActionAbout() {
  return (
    <>
     <section className="cta-section">
      <div className="cta-content">
        <h2 className="cta-title">Let’s Build the Future Together!</h2>
        <p className="cta-description">
          We are dedicated to helping businesses thrive in the digital age. Together, we can unlock endless possibilities with innovative technology and AI-driven solutions. Let’s create something amazing.
        </p>
        <Link to="/contact-us" className="cta-button">Start Your Digital Journey with Us</Link>
      </div>
    </section>
    
    
    
    
    
    </>
  )
}
